.MachinesCard_Box {
    width: 100%;
    height: 312px;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 0px 0px grey;
    border-radius: 15px;
    -webkit-transition: box-shadow .6s ease-out;
}

.MachinesCard_title {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    text-align: center;
    position: absolute;
    width: -webkit-fill-available;
    padding: 15px 0;
    background: #e0e0e0;
    border-radius: 0 0 15px 15px;

}

.MachinesCard_Box:hover .MachinesCard_title {
    background: #ebbe2a;
    color: #fff;

}

.MachinesCard_Box:hover {
    box-shadow: 1px 8px 50px grey;
    -webkit-transition: box-shadow .6s ease-in;
}

@media screen and (max-width: 768px) {
    .MachinesCard_Box {
        width: auto;
    }
}