.EquipmentCard {
    display: flex;
    background: #fff;
    margin-bottom: 20px;
    box-shadow: 0px 1px 8px -6px;
    cursor: pointer;
    flex-direction: column
}

.EquipmentCard_img {
    width: 30%;
}

.EquipmentCard_Data_box {
    width: 70%;
    padding: 10px 0 10px 20px;
}

.EquipmentCard_title {
    /* border-left: 7px solid #000; */
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.EquipmentCard_Data_title {
    color: #000;
    font-size: 33px;
    font-weight: 500;
}

.EquipmentCard_sub_title {
    color: #000;
    font-size: 25px;
    font-weight: 500;

}

.EquipmentCard_DETAIL_Box {
    margin-top: 10px;
}

.EquipmentCard_DETAILS {
    margin-left: 20px;
    font-size: 17px;
}

.Equipment_bird_crump {
    display: flex;
    margin: 20px 0;
}

.Equipment_bird_crump_title {
    font-weight: 600;
    color: #000;
    padding-left: 5px;
    font-size: 18px;
    cursor: pointer;
    word-break: break-all;
}

.Equipment_bird_crump_title:hover {
    color: #3366cc;
}

.EquipmentCard_Data_Img {
    object-position: center;
    object-fit: cover;
    width: 100%;
    max-height: 150px;
}

/* -------------------------- */
/* Custom Classes */

.EquipmentCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    /* height: 100%; /* Equivalent to h-auto in Tailwind */
    padding: 0.5rem;
    /* Equivalent to p-3 in Tailwind */
    border-radius: 0.5rem;
    /* Equivalent to rounded-md in Tailwind */
    border: 1px solid #718096;
    /* Equivalent to border border-slate-400 in Tailwind */
}

.card>*+* {
    margin-top: 1rem;
    /* Equivalent to space-y-4 in Tailwind */
}

.cursor-pointer {
    cursor: pointer;
}

.customImage {
    width: 100%;
    /* Equivalent to w-full in Tailwind */
    height: 250px;
    /* Adjust as needed, equivalent to h-80 in Tailwind */
    object-fit: cover;
    /* Equivalent to object-cover in Tailwind */
}

.customImage:hover {
    background-color: #6ee7b7;
    /* Adjust as needed, equivalent to hover:bg-cyan-300 in Tailwind */
}

.cardDescription {
    margin-top: 0.25rem;
}

.machine-data-title {
    color: #6ee7b7;
    font-size: 16px;
    transition: color 0.3s ease;
    color: #22d3ee;
    font-weight: 500;
    margin: 10px 0;
}

.machine-data-price {
    color: #fbbf24;
    font-size: 18px;
    user-select: none;
    margin: 10px 0;
}

.cyan-button {
    background-color: #10b981;
    /* Cyan color */
    color: #ffffff;
    /* White color */
    padding: 0.25rem 0.5rem;
    /* Vertical and horizontal padding */
    font-size: 0.875rem;
    /* Small font size */
    border-radius: 0.375rem;
    /* Rounded corners */
}

.cyan-button.disable:hover {
    pointer-events: none;
    /* Disable hover effect */
}

.machineData_meter_reading {
    margin-top: 1rem;
    /* Equivalent to mt-4 in Tailwind */
    color: #000000;
    /* Black color */
    font-size: 1rem;
    /* Medium font size */
    user-select: none;
    /* Disable text selection */
    margin: 10px 0;
}

.machineData_Hours {
    margin-top: 1rem;
    /* Equivalent to mt-4 in Tailwind */
    color: #000000;
    /* Black color */
    font-size: 16px;
    /* Large font size */
    user-select: none;
    /* Disable text selection */
}

.machine_data_Ask {
    background-color: #10b981;
    /* Cyan color */
    color: #ffffff;
    /* White color */
    padding: 0.25rem 0.5rem;
    /* Vertical and horizontal padding */
    margin-left: 0.5rem;
    /* Left margin */
    margin-right: 0.5rem;
    /* Right margin */
    font-size: 0.875rem;
    /* Small font size */
    border-radius: 0.375rem;
    /* Rounded corners */
    border: none
}

.machineData_location {
    color: #374151;
    /* Black-500 color */
    font-size: 16px;
    /* Medium font size */
    user-select: none;
    /* Disable text selection */
    height: 1.25rem;
    /* Equivalent to h-5 in Tailwind */
    margin: 10px 0;
}

.Contact-Us-Btn {
    background-color: #f8c933;
    /* Yellow-500 color */
    color: #000000;
    /* Black color */
    font-weight: normal;
    /* Normal font weight */
    padding: 8px 1rem;
    /* Vertical and horizontal padding */
    border-radius: 0.375rem;
    /* Rounded corners */
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
}

.Contact-Us-Btn:hover {
    background-color: #f59e0b;
    /* Yellow-600 color */
}

.Details-Btn {
    background-color: #000000;
    /* Black color */
    color: #ffffff;
    /* White color */
    /* margin: 0.5rem; */
    font-weight: normal;
    /* Normal font weight */
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    /* Rounded corners */
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    border: none;
}

.contact-us-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 10px;
}

/* -------------------------- */

@media screen and (max-width: 643px) {
    .EquipmentCard_img {
        width: 100%;
        height: 250px;
    }

    .EquipmentCard {
        flex-direction: column
    }

    .EquipmentCard_Data_title {
        font-size: 25px;
    }

    .EquipmentCard_sub_title {
        font-size: 15px;
    }
}

@media screen and (max-width: 480px) {
    #recaptcha_challenge_image {
        margin: 0 !important;
        width: 100% !important;
        height: auto !important;
    }

    #recaptcha_response_field {
        margin: 0 !important;
        width: 100% !important;
        height: auto !important;
    }

    .recaptchatable #recaptcha_image {
        margin: 0 !important;
        width: 100% !important;
        height: auto !important;
    }

    .recaptchatable .recaptcha_r1_c1,
    .recaptchatable .recaptcha_r3_c1,
    .recaptchatable .recaptcha_r3_c2,
    .recaptchatable .recaptcha_r7_c1,
    .recaptchatable .recaptcha_r8_c1,
    .recaptchatable .recaptcha_r3_c3,
    .recaptchatable .recaptcha_r2_c1,
    .recaptchatable .recaptcha_r4_c1,
    .recaptchatable .recaptcha_r4_c2,
    .recaptchatable .recaptcha_r4_c4,
    .recaptchatable .recaptcha_image_cell {

        margin: 0 !important;
        width: 100% !important;
        background: none !important;
        height: auto !important;
    }

}