.Carousel-detail {
    margin: 0px;
}

.carousel-bg {
    background: #323232;
}

.Carousel {
    box-shadow: 10px 10px #323232;
    width: auto;
}

.Carousel-img-detail {
    position: relative;
    cursor: pointer;
    height: 100%;
}

.carousel-img {
    height: 500px;
    display: flex;
    align-items: flex-end;
}

.carousel-img-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 0;
}

.EquipmentCard_Data_title_Detail {
    font-size: 35px;
    font-weight: 300;
}

.EquipmentCard_Data_Sub_title_Detail {
    font-size: 20px;
    font-weight: 300;
    margin: 30px 0;
}

.EquipmentCard_DETAILS_Page_Price {
    margin: 20px 0;
}

.Call_Price {
    text-decoration: none;
    color: #323232;
    cursor: pointer;
}

.EquipmentCard_DETAILS_Page_Learn_More_Btn {
    border: 1px solid #323232;
    border-radius: 20px;
    width: fit-content;
    padding: 5px 10px;
}

.EquipmentCard_DETAILS_Page_Learn_More_Btn:hover a {
    background: #323232;
    color: #fff;
}

.EquipmentCard_DETAILS_Page_Learn_More_Btn:hover {
    background: #323232;
    color: #fff;
}


.EquipmentCard_DETAILS_Page_Learn_More_Btn a {
    text-decoration: none;
    color: #323232;
}

.Equipment-details-box {
    padding: 60px 50px 50px 50px;
}

.HTML_Tag {
    display: none;
    width: -webkit-fill-available;
    text-align: start;
}

.Carousel-img-detail:hover .HTML_Tag {
    color: #fff;
    display: block;
}

.carousel_label {
    font-size: 30px;
    color: #323232;
    padding: 0 20px 5px 20px;
}

.carousel_sub_label {
    font-size: 20px;
    color: #323232;
    padding: 0 20px;
}

.detail-box-title {
    font-size: 24px;
    margin-top: 20px;
    font-weight: bold;
}

.grid {
    display: grid;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.specifications-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.mt-4 {
    margin-top: 12px;
}

.mt-5 {
    margin-top: 30px;
}

.font-bold {
    font-weight: bold;
}

.Call_for_Pricing {
    text-decoration: none;
    color: #000;
}

.htmlTable table {
    width: 100% !important;
}


.get-more-info-btn {
    background-color: #3B82f6;
    /* Yellow-500 color */
    color: #fff;
    /* Black color */
    font-weight: bold;
    /* Normal font weight */
    padding: 0.5rem 1rem;
    /* Vertical and horizontal padding */   
    border-radius: 10px;
    /* Rounded corners */
    border: none;
    font-size: 20px;
    cursor: pointer;
    height:max-content;
    margin-right: 10px;
}

.get-more-info-btn:hover {
    background-color: #f59e0b;
    /* Yellow-600 color */
}

.share-text{
    margin: 20px 0;
}

.share-icon img{
    width: 50px;
}

.share-box{
    text-align: end;
    display: flex;
    justify-content: flex-end
}

@media screen and (max-width: 1231px) {
    .Equipment-details-box {
        padding: 0px 20px 5px 20px;
    }

    .Carousel-detail {
        padding: 0;
    }
}


@media screen and (max-width: 1023px) {
    .carousel-img-grid {
        display: flex;
        flex-direction: column-reverse;
    }

    .Carousel {
        box-shadow: none
    }
}

@media screen and (max-width: 768px) {

    .Carousel-detail {
        margin: 30px 0 0 0;
        text-align: center;
    }

    .carousel-img-grid {
        display: flex;
        flex-direction: column-reverse;
    }

    .Equipment-details-box {
        text-align: center;
    }

    .Carousel {
        box-shadow: none
    }

    .EquipmentCard_DETAILS_Page_Learn_More_Btn {
        margin: auto;
    }

    .EquipmentCard_Data_title_Detail {
        margin-top: 50px;
    }

    .carousel-img {
        height: 400px;
    }

    .grid-cols-2 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .share-box{
        text-align: start;
        justify-content: flex-start
    }
}


@media screen and (max-width: 628px) {
    .EquipmentCard_Data_title_Detail {
        font-size: 25px;
    }

    .carousel-img {
        height: 200px;
    }

    .carousel_label {
        font-size: 20px;
    }

    .carousel_sub_label {
        font-size: 15px;
    }
}