.About_US {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    margin: 20px 0;
}


.About_US_right {
    background: url("../../assets/img/adout_us.png");
    mask-image: var(--mask-position, 0);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.About_US_left {
    padding: 135px 0px 135px 100px;
}

.About_US_Text {
    color: #323232;
    font-size: 40px;
    font-weight: 200;
    margin-bottom: 40px;
    font-weight: 600;
}

.About_US_sub_text {
    color: #323232;
    font-size: 18px;
    margin-bottom: 40px;
}

.About_US_sub_text_two {
    color: #323232;
    font-size: 15px;
    line-height: 30px;
}

.About_US_Mini_Screen {
    display: none;
}

.About_US_Big_Screen {
    display: block;
}

@media screen and (max-width: 1024px) {
    .About_US {
        display: flex;
        flex-direction: column-reverse
    }

    .About_US_right {
        background: url("../../assets/img/adout_us.png"), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        background-blend-mode: overlay;
    }


    .About_US_left {
        padding: 0;
    }

    .About_US_Big_Screen {
        display: none;
    }

    .About_US_Mini_Screen {
        display: block;
        padding: 50px;
        color: #fff;
    }

    .About_US_Text {
        color: #fff;
    }

    .About_US_sub_text {
        color: #fff;
    }

    .About_US_sub_text_two {
        color: #fff;
    }
}


@media screen and (max-width: 401px) {
    .About_US_Text {
        font-size: 25px;
        margin-bottom: 20px;
    }

    .About_US_sub_text {
        margin-bottom: 20px;
    }

    .About_US_Address {
        font-size: 13px;
    }

}