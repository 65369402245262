.sales_box {
    text-align: center;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.sales_title{
    font-size: 35px;
    font-weight: 200;
}

.sales_dec{
    margin-top: 20px;
    font-size: 15px;
    max-width: 800px;
    line-height: 28px;
}