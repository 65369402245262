.ContactUs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    margin: 20px 0;
}

.ContactUs_left {
    background: #323232;
    padding: 122px 0px 122px 100px;
}

.ContactUs_right {
    background: url("../../../assets/img/bg2.jpg");
    mask-image: var(--mask-position, 0);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.ContactUs_Text {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: 300;
}

.ContactUs_Address {
    color: #fff;
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 200;
}

.ContactUs_Address img {
    width: 30px;
    margin-top: 5px;
}

.ContactUs_Address a {
    color: #fff;
    text-decoration: none;
}

@media screen and (max-width: 1024px) {
    .ContactUs {
        display: flex;
        flex-direction: column-reverse
    }

    .ContactUs_right {
        height: 400px;
    }
}

@media screen and (max-width: 768px) {
    .ContactUs_left {
        padding: 50px;
    }
}

@media screen and (max-width: 401px) {
    .ContactUs_Text {
        font-size: 25px;
    }

    .ContactUs_right {
        height: 330px;
    }

    .ContactUs_Address {
        font-size: 13px;
    }

    .ContactUs_left {
        padding: 40px 50px
    }
}