.With_US_Data_Lists {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 20px 0;
}

.with_us_img {
    text-align: center;
    display: flex;
    justify-content: center;
}

.with_us_img img {
    width: 100%;
}

.with_us_title {
    font-size: 22px;
    text-align: center;
    color: #323232;
    font-weight: 300;
    margin: 30px 0;
    line-height: 1.875em;
}

.with_us_dec {
    font-size: 15px;
    text-align: center;
    color: #323232;
    font-weight: 300;
    max-width: 500px;
    margin: auto;
    line-height: 1.875em;
}

.with_us_card{
    margin-bottom: 30px;
}


@media screen and (max-width: 1200px) {
    .with_us_img img {
        width: 80%;
    }
}


@media screen and (max-width: 1024px) {
    .With_US_Data_Lists {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .With_US_Data_Lists {
        grid-template-columns: repeat(1, 1fr);
    }
}