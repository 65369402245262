.FaqBanner {
    background: url(../../../assets/img/faq-background.jpeg);
    padding: 200px 0;
    text-align: center;
    mask-image: var(--mask-position,0);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.faq_title {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
}

.faq_sub_title {
    color: #fff;
    font-size: 18px;
}


@media screen and (max-width: 861px) {
    .FaqBanner{
        padding: 150px 0;
    }
}


@media screen and (max-width: 600px) {
    .FaqBanner{
        padding: 50px 0;
    }

    .faq_title {
        color: #fff;
        font-size: 25px;

    }

    .faq_sub_title {
        color: #fff;
        font-size: 18px;
    }
}