.dashboard_text_one {
    text-align: center;
    line-height: 1.25em;
    font-size: 50px;
}

.dashboard_banner_right_Text {
    display: none;
}

.dashboard_banner_left_Text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard_text_two {
    text-align: center;
    line-height: 1.67em;
    font-size: 20px;
    margin-top: 20px;
}

.dashboard_banner_left {
    background: #323232;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 50%; */
    min-height: 65vh;
    padding: 20px;
}

.Get_In_Touch_But {
    border-radius: 50px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    padding: 15px 20px;
    font-size: 15px;
    margin-top: 20px;
}

.Get_In_Touch_But:hover {
    background: #fff;
    color: #000;
}

.dashboard_banner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 0;

}

.dashboard_banner_right {
    background-image: url("../../../assets/img/bg.jpg");
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
}

@media screen and (max-width: 968px) {
    .dashboard_banner_left {
        width: auto;
        background: transparent;
    }

    .dashboard_banner {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        margin: 20px 0;
        background: url("../../../assets/img/bg.jpg"), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
        background-blend-mode: overlay;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        mask-image: var(--mask-position, 0);
    }
}

@media screen and (max-width: 768px) {
    .dashboard_text_one {
        font-size: 30px;
    }

    .dashboard_text_one {
        text-align: start;
    }

    .dashboard_text_two {
        text-align: start;
    }

    .dashboard_banner_left {
        padding: 20px;
        align-items: flex-start
    }
}

@media screen and (max-width: 425px) {
    .dashboard_text_one {
        line-height: 1.25em;
        font-size: 25px;
    }
}