/* body {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} */

/* body {
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
	perspective: 1px;
	-webkit-perspective: 1px;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
} */

.spinner-container {
	display: flex;
	align-items: center;
}

.loading-spinner {
	width: 50px;
	height: 50px;
	border: 10px solid #f3f3f3;
	/* Light grey */
	border-top: 10px solid #383636;
	/* Black */
	border-radius: 50%;
	animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
	0% {
	  transform: rotate(0deg);
	}
  
	100% {
	  transform: rotate(360deg);
	}
  }

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}