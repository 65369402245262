.Header {
  background: #fff;
  font-weight: 600;
  padding: 20px 0;
}

.Header_Box {
  border-bottom: 1px solid;
  padding: 0px 0px 20px 00px;
  margin-bottom: 20px;
}

.navigation_link_header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}

.navigation_link_header_list {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}

.navigation_link_header_part {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navigation_header_logo_img {
  color: #fff;
  margin: 0 10px;
  cursor: pointer;
  width: 400px;
}

.navigation_header_link {
  color: #262626;
  margin: 0 10px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}

.navigation_header_link:hover {
  color: #efc442;
}

.navigation_header_link_activeTab {
  color: #efc442 !important;
}

.Drawer {
  padding-top: 50px;
}

.mini_screen_navigation_header_link {
  color: #262626;
  margin: 10px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.CONTACT_btn {
  background: #241f23;
  border: 0px solid #241f23;
  border-radius: 20px;
  padding: 8px 15px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

.CONTACT_btn_Black_Color {
  background-color: #000;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  border: 0;
}

.navigation_header_icone {
  width: 20px;
  margin: 0 10px;
  cursor: pointer;
}

.navigation_link_header_mini_screen {
  display: none;
}

.dropbtn_icon {
  width: 20px;
}

.dropbtn {
  margin-top: 10px;
  color: white;
  padding: 8px;
  font-size: 16px;
  border: none;
  background: #fafafa;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #000;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: center;
  padding: 20px 0;
  right: 0;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  cursor: pointer;
}

.NavLink {
  text-decoration: none;
}

.contact_grid_box_top {
  display: flex;
}

.contact_field {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.contact-input-label {
  color: #3f4b5b;
}

.contact_input {
  border: 1px solid #bfc3c8;
  width: 100%;
  border-radius: 5px;
  height: 25px;
  margin-top: 3px;
}

.contact_input_textarea {
  border: 1px solid #bfc3c8;
  width: 100%;
  border-radius: 5px;
  margin-top: 3px;
}

.error_message {
  color: red;
}

.google_recaptch_box {
  display: flex;
  align-items: center;
}

.button_grid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_grid_model {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.Submit_btn {
  margin: 5px;
  color: hsl(0, 0%, 100%);
  background: hsl(45deg 94% 44%);
  border-radius: 10px;
  padding: 12px 25px;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.cancel_btn {
  margin: 5px;
  color: hsl(0, 0%, 100%);
  background: #1010104d;
  border-radius: 10px;
  padding: 12px 25px;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.Submit_loading_btn {
  margin: 5px;
  color: hsl(0, 0%, 100%);
  background: #1010104d;
  border-radius: 10px;
  padding: 12px 25px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  cursor: not-allowed;
}

.phone_number_header_a_tag {
  color: #000;
}

.phone_number_header_a_tag:hover {
  color: #000;
}

.loader_Box {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  background: #ffffff78;
}

.header_Slogan {
  display: flex;
}

.header_Slogan_Link a {
  text-decoration: none;
  color: #000;
  margin-left: 5px;
  line-height: 30px;
}

.header_Phone_Link a {
  text-decoration: none;
  color: #000;
}
.header_Phone_Link {
  margin: auto;
  text-align: center;
}

.header_Slogan_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Header_Btn {
  color: #000;
  background: #fff;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
  border: 1px solid;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.header_Number_Box {
  display: flex;
  align-items: center;
}

.header_Btn_Md {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Header_Btn:hover {
  color: #fff;
  background: #000;
}

.Header_Btn a {
  text-decoration: none;
  color: #000;
}

.Header_Btn:hover a {
  text-decoration: none;
  color: #fff;
}

.Header_Btn_Box {
  display: flex;
  flex-direction: column;
}

.mini_Header_Btn_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close_icon {
  margin: 0 20px;
  text-align: right;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .about_inventory_keyword_box {
    width: 680px;
  }
}

@media screen and (max-width: 1019px) {
  .navigation_link_header {
    flex-direction: column;
  }

  .header_Slogan_Box {
    margin-right: 0;
    margin: 5px 0;
  }

  .header_Number_Box {
    flex-direction: column;
  }
}

@media screen and (max-width: 920px) {
  .about_inventory_keyword_box {
    width: 320px;
  }
}

@media screen and (max-width: 873px) {
  .mini_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header_Slogan_Box {
    align-items: center;
  }

  .Header_Box {
    border-bottom: 0px;
  }

  .header_Slogan_Link {
    text-align: center;
  }
}

@media screen and (max-width: 872px) {
  .navigation_link_header {
    display: none;
  }

  .navigation_link_header_list {
    display: none;
  }

  .navigation_link_header_mini_screen {
    display: block;
    text-align: end;
  }

  .navigation_header_link {
    margin: 0 5px;
    color: #fff;
    font-size: 14px;
  }

  .Header_Box {
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .contact_grid_box_top {
    flex-direction: column;
  }

  .navigation_link_header {
    display: none;
  }

  .navigation_link_header_list {
    display: none;
  }

  .navigation_link_header_mini_screen {
    display: block;
    text-align: end;
  }

  .navigation_header_link {
    margin: 0 10px;
  }
}

@media screen and (max-width: 709px) {
  .mini_header {
    flex-direction: column;
  }
}

@media screen and (max-width: 425px) {
  .navigation_header_logo_img {
    width: 200px;
  }
}

@media screen and (max-width: 368px) {
  .about_inventory_keyword_box {
    width: 100%;
  }
}
