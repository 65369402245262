.Equipment_Grid {
    /* display: grid;
    grid-template-columns: 75% 25%; */
}

.EquipmentDataList {
    min-height: 80vh;
}

.about_inventory_btn_filter_key_word {
    background-color: #ebb405;
    border-radius: 20px;
    padding: 10px;
    color: #fff;
    /* font-family: GoodTimes; */
    font-weight: 400;
    font-size: 16px;
    width: fit-content;
    display: flex;
    align-items: center;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 20px;
}

.about_inventory_btn_filter_icon {
    width: 20px;
    margin-left: 5px;
    cursor: pointer;
}

.No_Record {
    font-size: 30px;
}

.filter_key_button {
    border-radius: 10px;
    border: none;
    background: #efc442;
    color: #fff;
    padding: 10px 17px;
    font-weight: 600;
    cursor: pointer;
}

.Select_Category {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.Select_Category_Text {
    margin-right: 10px;
}

.modal {
    position: relative;
}

.Equipment_Card_Grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

/* -------------------- */

@media only screen and (max-width: 768px) {

    /* .Equipment_Grid {
        display: flex;
        flex-direction: column-reverse;
    } */
    .Equipment_Card_Grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .EquipmentDataList {
        margin-right: 0;
    }

    .EquipmentDataList {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 671px) {

    .Equipment_Card_Grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }
}