.Inventory_Heading {
    text-align: center;
    font-size: 40px;
    font-weight: 200;
}

.Inventory {
    margin: 60px 0;
}

.Machines_Data_Lists {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 20px 0;
}

.Inventory_Sub_Heading{
    margin: 30px 0;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
}

@media screen and (max-width: 1024px) {

    .Machines_Data_Lists {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .Machines_Data_Lists {
        grid-template-columns: repeat(1, 1fr);
    }
}