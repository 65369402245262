.footer {
    /* background: #fff; */
    padding: 50px 0;
    border-top: 1px solid #000;
    margin-top: 50px;
}

.footer_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.footer_logo_text {
    font-weight: bold;
    color: #fff;
    font-size: 20px;
}

.logo_ieda {
    width: 90%;
}

.footer_logo_grid {
    margin-bottom: 20px;
}

.footer_contact_link div {
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}

.footer_contact_link {
    text-decoration: none;
}

.footer_Address {
    color: #262626;
    font-weight: 500;
    text-align: center;
}

.footer_Address a {
    color: #f8c933;
    font-weight: bold;
}

.footer_Address:hover a {
    color: #f8c933;
    font-weight: bold;
    /* background: #3e3e3e;
    padding: 5px 3px; */
}

.footer_page_link {
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 2px;
    color: #fff;
    text-decoration: none;
}

.footer_page_grid {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.footer_subscribe_box {
    display: flex;
    justify-content: flex-end
}

.footer_s_l {
    display: flex;
    margin-top: 5px;
}

.footer_s_l_img {
    width: 30px;
    margin-right: 5px;
}

.CONTACT_btn_Yellow_Color {
    background-color: #f8c933;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    color: #323232;
    cursor: pointer;
    font-family: Roboto;
    border: 0;
    border-radius: 10px;
}

.footer_input_box {
    padding: 10px;
    font-size: 16px;
    color: rgba(38, 38, 38, 1);
    margin-right: 10px;
    border-radius: 10px;
}


.Copyright {
    text-align: center;
    color: #262626;
    font-weight: 600;
}

.footer-bg {
    background: #323232;
}

.Privacy_Policy_Box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Privacy_Policy_Text{
    margin: 0 20px;
}

.Privacy_Policy_Text p{
    cursor: pointer;
    color: #f8c933;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.Cookie_Policy_Modal{
    width: 80vw;
    height: 80vh;
}

@media only screen and (max-width: 1154px) {
    .footer_grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}


@media only screen and (max-width: 588px) {
    .footer_grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .footer_Address {
        text-align: left;
    }

    .footer_subscribe_box {
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 20px;
    }
}