.contact_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 50px 0 20px 0;

}

.contact_left_grid {
    text-align: center;
    margin-bottom: 20px;
}

.contact_right_grid {}

.contact_title {
    font-weight: 300px;
    font-size: 40px;
    margin-bottom: 30px;
    color: #323232;
    font-weight: 600;
}

.contact_sub_title {
    font-weight: 200px;
    font-size: 15px;
    margin-bottom: 15px;
    color: #323232;
}

.contact_sub_title a {
    text-decoration: none;
    color: #323232;
}

.contact_sub_video {
    margin-top: 50px;
}

.contact_sub_video iframe{
    width: 350px;
}

.Contact_Page_field {
    display: flex;
    flex-direction: column;
    margin: 10px 10px 0px 0;
    width: -webkit-fill-available
}

.Contact_Page_Right_Box {
    margin: 0 90px;
}

.contact_us_button {
    background: #f8c933;
    width: -webkit-fill-available;
    color: #fff;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 200;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.contact_form_title {
    font-size: 40px;
    font-weight: 600;
    color: #262626;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
}

.contact_form_title img {
    margin-left: 10px;
    position: absolute;
    left: 355px;
    bottom: 0;
}

.button_grid {
    margin-top: 10px;
}

.label {
    font-size: 14px;
    margin-bottom: 10px;
}

.Contact_Page_Right_Box {
    width: -webkit-fill-available;
}

.google_recaptch_box {
    justify-content: center;
}


.contact_grid_box_top {
    display: flex;
}

.contact_page_input {
    border: 1px solid #bfc3c8;
    width: -webkit-fill-available;
    border-radius: 5px;
    height: 25px;
    margin-top: 3px;
    padding: 5px;
    font-family: 'Roboto', sans-serif;
}

.error_message {
    color: red;
}

.contact_page_input_textarea {
    border: 1px solid #bfc3c8;
    border-radius: 5px;
    margin-top: 3px;
    padding: 5px;
}

.contact_page_input_textarea {
    font-family: 'Roboto', sans-serif;

}

.Contact_Page_Right_div {
    box-shadow: 0px 0px 5px 1px #c3c3c3;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 20px;
    position: relative;
}

@media screen and (max-width: 1206px) {
    .contact_form_title {
        margin-right: 44px;
    }

    .contact_form_title img {
        left: 162px
    }
}

@media screen and (max-width: 994px) {
    .contact_form_title img {
        left: 350px
    }
}


@media screen and (max-width: 894px) {
    .contact_grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        margin: 20px 0;
    }

    .contact_right_grid {
        margin-top: 30px;
    }
}

@media screen and (max-width: 768px) {
    .contact_grid_box_top {
        flex-direction: column;
    }
}

@media screen and (max-width: 602px) {
    .Contact_Page_Right_Box {
        margin: 0;
    }
}

@media screen and (max-width: 534px) {

    .contact_form_title img {
        left: 162px
    }
}