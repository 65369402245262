.Search_Input_Box {
    padding: 10px;
    background: #323232;
}

.Search_Key_Word_Box {
    background: #000;
    padding: 10px 0;
    max-height: 90vh;
    overflow: scroll;
}

.Search_Key_Word_Key {
    color: #fff;
    font-size: 18px;
    padding: 5px 10px;
    cursor: pointer;
}

.Search_Key_Word_Key_Select {
    color: #fff;
    font-size: 18px;
    padding: 5px 10px;
    background: #323232;
    cursor: pointer;
}

.Search_Key_Word_Key:hover {
    background: #323232;
}

.Search_Input_link {
    color: #000;
    padding-bottom: 25px;
    text-decoration: none;
    text-align: center;
}

.line_break {
    line-break:anywhere;
}



.Search_box {
    padding: 10px;
    font-size: 16px;
    color: rgba(38, 38, 38, 1);
    margin-right: 10px;
    width: -webkit-fill-available;
}

.Dashboard_Navigation_Back_Btn {
    text-align: end;
    display: flex;
    margin: 0 7px 5px;
    justify-content: flex-start;
    align-items: center;
}

.Dashboard_Navigation_Back_Btn_Color{
    color: #fff;
}

.select-box{
    padding: 10px 5px;
    border-radius: 10px;
}