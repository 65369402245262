.Faq_Card {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    margin: 100px 100px;
}

.FaqCard {
    display: flex;
    flex-direction: column
}

.Faq_Title {
    color: #323232;
    font-size: 22px;
    margin-bottom: 40px;
    min-height: 50px;
}

.Faq_Sub_Title {
    color: #323232;
    font-size: 15px;
}


@media screen and (max-width: 1024px) {
    .Faq_Card {
        margin: 100px 20px;
    }

    .Faq_Title {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .Faq_Card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        margin: 100px 100px;
    }
}

@media screen and (max-width: 700px) {
    .Faq_Card {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 50px;
        margin: 0;
    }

    .Faq_Title{
        min-height:auto;
        margin-bottom:10px
    }
}